<template>
  <!--    PromotionStandard Form-->
  <validation-observer
    ref="promotionStandardForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updatePromotionStandardMode?'Update':'Create'} Promotion Standard`"
      :show="promotionStandardModalOpened"
      :show-overlay="deletingPromotionStandard"
      size="md"
      @onClose="closePromotionStandardModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedPromotionStandardId"
            placeholder="All Promotion Standards"
            :options="promotionStandards"
            label-name="title"
            value-name="id"
            @input="populatePromotionStandardForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deletePromotionStandard()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetPromotionStandardForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <lenon-input
        v-model="promotionStandard.title"
        name="title"
        placeholder="Enter standard title, eg. SHS Promotion Standard"
        rules="required"
        label="Title"
      />
      <lenon-input
        v-model="promotionStandard.absence_rate"
        name="absence_rate"
        placeholder="Absence Rate (%)"
        type="number"
        rules="required"
        label="Absence Rate"
        suffix-icon="HelpCircleIcon"
        tooltip="This is the number of times students absent themselves for the entire academic year expressed as a percentage,
            students with absence rate above this are likely to be repeated.
            eg. If total school days for the academic year is 60 and you enter 50 (which means 50%) as an absence rate, Lenon will consider any student
            who was absent more than 30 times as candidates to repeat unless they do really well in other factors you set, like maximum cum average."
      />
      <lenon-input
        v-model="promotionStandard.min_cum_average"
        name="min_cum_average"
        placeholder="Minimum Cum Average (%)"
        type="number"
        rules="required"
        label="Minimum Cum Average"
        suffix-icon="HelpCircleIcon"
        tooltip="This is the minimum cumulative average you do not want your students to fall below, students with cum average below this are likely to be repeated"
      />
      <lenon-input
        v-model="promotionStandard.max_cum_average"
        name="max_cum_average"
        placeholder="Max Cum Average (%)"
        type="number"
        label="Max Cum Average"
        suffix-icon="HelpCircleIcon"
        tooltip="This is the cumulative average above which students will be promoted regardless of other factors."
      />
      <div class="mt-2">
        <lenon-select
          v-model="promotionStandard.consider_improvement"
          name="consider_improvements"
          placeholder="Consider Improvement ?"
          label-name="name"
          value-name="id"
          :options="considerImprovements"
          label="Consider Improvement"
        />
        <div style="margin-top: -10px !important;">
          <small><b>If Yes, students who have been improving will be favored during promotion.</b></small>
        </div>
      </div>
      <lenon-input
        v-show="promotionStandard.consider_improvement"
        v-model="promotionStandard.improvement_rate"
        name="improvement_rate"
        placeholder="Improvement Rate (%)"
        type="number"
        :rules="promotionStandard.consider_improvement?'required':''"
        label="Improvement Rate"
        suffix-icon="HelpCircleIcon"
        tooltip="Lenon needs to know what improvement means to you, if you enter 10 (which means 10%),
        Lenon will only consider students who improved by 10% or more as students who are improving."
      />
      <div class="mt-2">
        <lenon-multi-select
          v-model="promotionStandard.classes"
          name="classes"
          label-name="name"
          value-name="id"
          placeholder="Select Classes"
          :options="filteredClasses"
          rules="required"
          label="Affected Classes"
          @remove="handleRemoveClass"
        />
        <div v-html="missingClassText('promotion standards')" />
      </div>
      <div class="mt-1">
        <b-form-checkbox
          v-model="allClasses"
          class="mt-1"
        >
          Select All Classes
        </b-form-checkbox>
      </div>
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closePromotionStandardModal()"
          />
          <lenon-button
            :label="updatePromotionStandardMode?'Update':'Create'"
            :disabled="invalid"
            :loading="promotionStandardLoading"
            loading-text="Loading..."
            @onClick="updatePromotionStandardMode?updatePromotionStandard():createPromotionStandard()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BDropdownItem, BDropdownDivider, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import {
  CREATE_PROMOTION_M,
  DELETE_PROMOTION_M,
  UPDATE_PROMOTION_M,
} from '@/graphql/mutations'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import logData from '@/libs/log'
// eslint-disable-next-line import/named
import { missingClassText } from '@/libs/dnd'

export default {
  name: 'PromotionStandard',
  components: {
    ErrorDisplay,
    LenonMultiSelect,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    BFormCheckbox,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      removedClassIds: [],
      missingClassText,
      allClasses: false,
      promotionStandardModalOpened: false,
      updatePromotionStandardMode: false,
      promotionStandardLoading: false,
      deletingPromotionStandard: false,
      selectedPromotionStandardId: null,
      promotionStandard: {
        id: null,
        title: null,
        absence_rate: null,
        min_cum_average: null,
        max_cum_average: null,
        consider_improvement: null,
        improvement_rate: null,
        classes: [],
      },
      considerImprovements: [{
        name: 'Yes',
        id: 1,
      }, {
        name: 'No',
        id: 0,
      }],
    }
  },
  computed: {
    selectedPromotionStandard() {
      return this.promotionStandards.find(ter => ter.id === this.selectedPromotionStandardId)
    },
    promotionStandards() {
      return this.$store.getters['studentPromotion/promotionStandards']
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
    filteredClasses() {
      let selectedClasses = []
      // compile already selected classes
      this.promotionStandards.forEach(gs => {
        selectedClasses = [...selectedClasses, ...gs.classes.map(c => c)]
      })
      // return classes not selected yet
      return this.classes.filter(cls => {
        if (this.removedClassIds.includes(cls.id)) {
          return true
        }
        return !selectedClasses.includes(cls.id)
      })
    },
  },
  watch: {
    allClasses(checked) {
      if (checked) {
        this.promotionStandard.classes = this.filteredClasses
      } else {
        this.promotionStandard.classes = []
      }
    },
    modalOpened(opened) {
      this.promotionStandardModalOpened = opened
    },
  },
  methods: {
    handleRemoveClass(id) {
      this.removedClassIds.push(id)
    },
    // promotionStandard
    resetPromotionStandardForm() {
      this.promotionStandard = {
        id: null,
        title: null,
        absence_rate: null,
        min_cum_average: null,
        max_cum_average: null,
        consider_improvement: null,
        improvement_rate: null,
        classes: [],
      }
      this.updatePromotionStandardMode = false
      this.selectedPromotionStandardId = null
      this.allClasses = false
      this.$refs.promotionStandardForm.reset()
    },
    populatePromotionStandardForm(ps) {
      if (this.selectedPromotionStandard) {
        this.updatePromotionStandardMode = true
        this.removedClassIds = []
        this.promotionStandard = {
          ...this.selectedPromotionStandard,
          consider_improvement: this.selectedPromotionStandard.consider_improvement ? 1 : 0,
          classes: this.selectedPromotionStandard.classes.map(c => this.classes.find(cl => c === cl.id)),
        }
        // eslint-disable-next-line no-underscore-dangle
        delete this.promotionStandard.__typename
        // eslint-disable-next-line no-underscore-dangle
        delete this.promotionStandard._showDetails
      } else {
        this.resetPromotionStandardForm()
      }
    },
    closePromotionStandardModal() {
      // this.removedClassIds = []
      this.promotionStandardModalOpened = false
      this.$emit('modalClosed')
    },
    updatePromotionStandard() {
      this.error = {}
      if (!this.promotionStandard.id) {
        return
      }
      const promotion = {
        ...this.promotionStandard,
        classes: this.promotionStandard.classes.map(p => p.id),
        absence_rate: +this.promotionStandard.absence_rate,
        min_cum_average: +this.promotionStandard.min_cum_average,
        max_cum_average: +this.promotionStandard.max_cum_average,
        consider_improvement: !!this.promotionStandard.consider_improvement,
        improvement_rate: +this.promotionStandard.improvement_rate,
      }
      this.promotionStandardLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_PROMOTION_M,
        variables: { input: promotion },
      })
        .then(res => {
          this.showSuccess('Updated promotion standard successfully')
          this.promotionStandardLoading = false
          this.$store.commit('studentPromotion/updatePromotionStandard', res.data.updatePromotion)
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to update promotion standard')
          this.promotionStandardLoading = false
        })
    },
    createPromotionStandard() {
      this.error = {}
      this.promotionStandardLoading = true
      const promotion = {
        ...this.promotionStandard,
        classes: this.promotionStandard.classes.map(p => p.id),
        absence_rate: +this.promotionStandard.absence_rate,
        min_cum_average: +this.promotionStandard.min_cum_average,
        max_cum_average: +this.promotionStandard.max_cum_average,
        consider_improvement: !!this.promotionStandard.consider_improvement,
        improvement_rate: +this.promotionStandard.improvement_rate,
      }
      this.$apollo.mutate({
        mutation: CREATE_PROMOTION_M,
        variables: { input: promotion },
      })
        .then(res => {
          this.showSuccess('Created promotion standard successfully')
          this.promotionStandardLoading = false
          this.$store.commit('studentPromotion/addPromotionStandard', res.data.createPromotion)
          this.resetPromotionStandardForm()
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to create promotion standard')
          this.promotionStandardLoading = false
        })
    },
    deletePromotionStandard() {
      if (!this.selectedPromotionStandardId) {
        this.showInfo('Please select a promotion standard')
        return
      }
      this.deletingPromotionStandard = true
      this.$apollo.mutate({
        mutation: DELETE_PROMOTION_M,
        variables: { id: this.selectedPromotionStandardId },
      })
        .then(() => {
          this.deletingPromotionStandard = false
          this.showSuccess('Deleted Successfully')
          this.$store.commit('studentPromotion/removePromotionStandard', this.selectedPromotionStandardId)
          this.selectedPromotionStandardId = null
          this.resetPromotionStandardForm()
        })
        .catch(() => {
          this.deletingPromotionStandard = false
        })
    },

  },
}
</script>
